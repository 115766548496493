var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "p": ['8px', '16px'],
      "align-items": "center",
      "width": ['100%', '600px'],
      "mx": "auto",
      "mb": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['100px', '150px'],
      "h": ['100px', '150px'],
      "flex-shrink": "0"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "circle",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-direction": "column",
      "justify": "center",
      "ml": ['1.5rem', '2rem']
    }
  }, _vm._l(3, function (index) {
    return _c('c-box', {
      key: index
    }, [_c('c-box', {
      attrs: {
        "w": ['50px', '80px'],
        "h": ['14px', '20px'],
        "mb": ['4px', '8px'],
        "overflow": "hidden",
        "border-radius": "4px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade"
      }
    })], 1), _c('c-box', {
      attrs: {
        "w": ['180px', '200px'],
        "h": ['18px', '24px'],
        "mb": index == 3 ? '0px' : ['12px', '20px'],
        "overflow": "hidden",
        "border-radius": "4px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade"
      }
    })], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }