<template>
  <c-flex
    :p="['8px', '16px']" 
    align-items="center"
    :width="['100%', '600px']"
    mx="auto"
    mb="16px"
  >
    <c-box
      :w="['100px', '150px']"
      :h="['100px', '150px']"
      flex-shrink="0"
    >
      <vue-skeleton-loader
        type="circle"
        width="100%"
        height="100%"
        rounded
        animation="fade"
      />
    </c-box>
    <c-flex
      flex-grow="1"
      flex-direction="column"
      justify="center"
      :ml="['1.5rem', '2rem']"
    >
      <c-box
        v-for="index in 3"
        :key="index"
      >
        <c-box
          :w="['50px', '80px']"
          :h="['14px', '20px']"
          :mb="['4px', '8px']"
          overflow="hidden"
          border-radius="4px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
          />
        </c-box>
        <c-box
          :w="['180px', '200px']"
          :h="['18px', '24px']"
          :mb="index == 3 ? '0px' : ['12px', '20px']"
          overflow="hidden"
          border-radius="4px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
          />
        </c-box>
      </c-box>
    </c-flex>
  </c-flex>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'  

export default {
  name: 'SkeletonProgram',
  components: { VueSkeletonLoader },
}
</script>
