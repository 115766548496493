<template>
  <c-flex
    h="64px"
    align-items="center"
    border-bottom="1px solid #C4C4C4"
    :border-top="borderTop ? '1px solid #C4C4C4' : 'none'"
  >
    <c-link 
      as="router-link" 
      :to="to"
      w="100%"
      display="flex"
      align-items="center"
      justify-content="space-between"
      p="16px"
    >
      <c-text
        font-family="Roboto"
        font-weight="500"
      >
        {{ title }}
      </c-text>
      <c-image
        :src="require('@/assets/ic-chevron-right-black.svg')"
        alt="icon arrow right"
      />
    </c-link>
  </c-flex>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    borderTop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
