var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "h": "64px",
      "align-items": "center",
      "border-bottom": "1px solid #C4C4C4",
      "border-top": _vm.borderTop ? '1px solid #C4C4C4' : 'none'
    }
  }, [_c('c-link', {
    attrs: {
      "as": "router-link",
      "to": _vm.to,
      "w": "100%",
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "p": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/ic-chevron-right-black.svg'),
      "alt": "icon arrow right"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }